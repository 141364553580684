import axios from "axios";
import router from '@/router'  //引入router

// http request 拦截器
axios.interceptors.request.use(
  config => {
    // const token = sessionStorage.getItem('token')
    // if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    //   config.headers.Authorization = token  //请求头加上token
    // }
    return config
  },
  err => {
    return Promise.reject(err)
  })
// http response 拦截器
axios.interceptors.response.use(
  response => {
    //拦截响应，做统一处理 
    // if (response.data.code) {
    //   switch (response.data.code) {
    //     case 1002:
    //       store.state.isLogin = false
    //       router.replace({
    //         path: '/',
    //         query: {
    //           redirect: router.currentRoute.fullPath
    //         }
    //       })
    //   }
    // }
    return response.data
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {
    return Promise.reject(error.response.status) // 返回接口返回的错误信息
  })
export default axios;