<template>
  <div class="home">
    <div class="main_top_img">
      <img src="../assets/main_top_img.png" />
    </div>
    <div class="box">
      月猪中小企业费控操作指南
    </div>
    <div
      class="box_list"
      v-for="item in table_list"
      :key="item.id"
      @click="jump_1(item.id)"
    >
      <div style="float:left" class="icon_img">
        <img src="../assets/list_icon.png" alt="" />
      </div>
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import axios from "../request/index";
export default {
  name: "Home",
  data() {
    return {
      table_list: []
    };
  },
  methods: {
    jump_1(id) {
      this.$router.push("/Details?id=" + id);
    },
    getTableList() {
      axios.post("api/help/queryHelpList").then(res => {
        console.log(res);
        this.table_list = res.data.data;
      });
    }
  },
  mounted() {},
  created() {
    this.getTableList();
  }
};
</script>
<style>
.box {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin-top: 42px;
  margin-bottom: 33px;
}
.main_top_img {
  width: 100%;
}
.main_top_img img {
  width: 100%;
}
.icon_img {
  width: 17px;
  height: 17px;
  margin-right: 7px;
}
.icon_img img {
  width: 100%;
}
.box_list {
  padding: 0px 32px;
  margin-bottom: 23px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 18px;
  color: #5395f2;
  opacity: 1;
  text-align: left;
}
</style>
